.bnr-thankPage {
  height: 100vh!important;
}
.bnr-thankPage h1 {
  font-size: 48px;
  line-height: 63px;
  color: #B2CEC9;
}
.bnr-thankPage .heading-wrapper {
  bottom: 181px!important;
}
.bnr-thankPage p {
  font-family: FS-Siena-Regular;
  font-size: 18px;
  line-height:26px ;
  color: #FFFFFF;
  letter-spacing: 0px;
  margin: 40px 0px 30px;
}
.backHomeBtn {
  font-family: FS-Siena-Regular;
  text-transform: uppercase;
  font-size: 14px;
  line-height:26px ;
  color: #FFFFFF;
  letter-spacing: 1px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  padding-bottom: 10px;
}


@media (max-width: 1700px) {
  .bnr-thankPage h1 {
    font-size: clamp(30px,3.3vw,48px);
    line-height: clamp(39px,4vw,63px);
  }
  .bnr-thankPage p {
    font-size: clamp(14px,1vw,18px);
    line-height:clamp(20px,1.5vw,26px) ;
  }

}

  
@media (max-width: 575px) {
  .bnr-thankPage {
    height: 630px!important;
  }
  .bnr-thankPage h1 {
    font-size: 30px;
    line-height: 39px;
  }
  .bnr-thankPage .heading-wrapper {
    bottom: 170px!important;
    padding-top: 33px;
    border-top:1px solid rgba(255, 255, 255, 0.5);
  }
  .bnr-thankPage p {
    font-size: 13px;
    line-height:20px ;
    margin: 48px 0px 30px;
  }
  .backHomeBtn {
    font-size: 12px;
  }

}
