/*
|------------------------------------------------------
|  Global Style
|------------------------------------------------------
*/
body {
  background-color: #2a4549;
}
img {
  width: 100%;
}

a {
  text-decoration: none;
}

.global-padding {
  padding: 0px 4.5vw;
}

.is-flex {
  display: flex;
}

.is-relative {
  position: relative;
}

.container {
  max-width: 100%;
  padding: 0px;
}

/* lenis */
html.lenis {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}

/* end lenis */

/*
|------------------------------------------------------
|  Typograph
|------------------------------------------------------
*/

@font-face {
  font-family: EuclidSquare-SemiBold;
  src: url(fonts/EuclidSquare-SemiBold.ttf);
}
@font-face {
  font-family: GT-Ultra-Fine-Regular;
  src: url(fonts/GT-Ultra-Fine-Regular-Trial.otf);
}
@font-face {
  font-family: GT-Ultra-Fine-Ultra-Trial;
  src: url(fonts/GT-Ultra-Fine-Ultra-Trial.otf);
}
@font-face {
  font-family: FS-Siena-Regular;
  src: url(fonts/FS-Siena-Regular.ttf);
}

.btn {
  font-family: EuclidSquare-SemiBold;
  color: #FFFFFF;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  padding: 0px;
}

h1 {
  font-family: GT-Ultra-Fine-Regular;
  font-weight: normal;
  font-size: 88px;
  color: #ffffff;
  line-height: 115px;
  text-transform: uppercase;
  letter-spacing: -2px;
}
h2 {
  font-family: GT-Ultra-Fine-Regular;
  font-weight: normal;
  font-size: 48px;
  color: #3b545a;
  line-height: 63px;
  text-transform: uppercase;
  letter-spacing: -2px;
}
h3 {
  font-family: GT-Ultra-Fine-Regular;
  font-weight: normal;
  font-size: 50px;
  line-height: 46px;
  letter-spacing: -1px;
  color: #ffffff;
}
p {
  font-family: GT-Ultra-Fine-Regular;
  font-weight: normal;
  font-size: 28px;
  color: #3b545a;
  line-height: 36px;
  letter-spacing: -1px;
}

/*
|----------------------------------------------------------------
| landing loader
|----------------------------------------------------------------
*/
/* .main-body {
  opacity: 0;
} */
.landing-loader {
  position: fixed;
  top: 0%;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #2a4549;
  display: flex;
  justify-content: center;
  align-items: center;
  /* overflow: hidden; */
}
.landing-loader img {
  width: 647.4px;
  height: auto;
  opacity: 0;
}
/*
|----------------------------------------------------------------
| nav
|----------------------------------------------------------------
*/
header {
  background-color: #2a4549;
  padding: 0px 4.5vw;
  padding-top: 30px;
  padding-bottom: 30px;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  opacity: 1;
  transform: translateY(0%);
  transition: all 0.4s;
  /* opacity: 0; */
}
.hide {
  opacity: 0;
  transform: translateY(-100%);
}
.hide .nav .ashlyn-logo,
.headerShow .nav .ashlyn-logo {
  width: 54px;
  height: auto;
}
.hide,
.headerShow {
  padding-top: 18px;
  padding-bottom: 18px;
}
.nav {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}
.nav .ashlyn-literal-logo {
  width: 180px;
  height: auto;
}
.nav .ashlyn-logo {
  width: 76px;
  height: auto;
  transition: all 0.3s;
}
.nav .register-btn-wrapper {
  background: rgb(59,84,90);
  background: linear-gradient(90deg, rgba(59,84,90,1) 0%, rgba(128,159,158,1) 50%, rgba(59,84,90,1) 100%);
  border-radius: 25px;
  padding: 12px 42px;
}
.register-btn-wrapper:hover {
  color: #FFFFFF;
}
/*
|----------------------------------------------------------------
| banner
|----------------------------------------------------------------
*/
.bnr {
  background-color: #2a4549;
  height: 86vh;
  min-height: 720px;
  max-height: 1000px;
  width: 100%;
}
.bnr .heading-wrapper {
  position: absolute;
  left: 4.5vw;
  bottom: 82px;
}
.bnr .subject {
  font-family: FS-Siena-Regular;
  font-weight: normal;
  line-height: 29px;
  letter-spacing: 2px;
  font-size: 26px;
  color: #ffffff;
  overflow: hidden;
  margin-bottom: 29px;
}
.bnr .appear-text {
  overflow: hidden;
}
.bnr .appear-text .char {
  opacity: 0;
  transform: translateY(120px);
  transition: transform 1.5s, opacity 4s;
}
.bnr h1 .is-lightgreen {
  color: #b2cec9;
  margin-top: -20px;
}
/*
|----------------------------------------------------------------
| unveil-homes
|----------------------------------------------------------------
*/
.unveil-homes {
  background-color: #f0ebe6;
}
.unveil-homes h2 {
  margin-top: 305px;
  margin-bottom: 100px;
}
.unveil-homes h2 div {
  overflow: hidden;
}
.unveil-homes h2 span {
  display: inline-block;
  /* transform: translateY(100%); */
}
.unveil-homes p {
  width: 85%;
  margin-bottom: 300px;
  opacity: 1;
}

.img-al {
  background-color: #f0ebe6;
}

/*
|----------------------------------------------------------------
| image-collage-section
|----------------------------------------------------------------
*/
.image-collage-section {
  background-color: #f0ebe6;
}
.image-collage-section h2 {
  margin-top: 205px;
  margin-bottom: 156px;
}
.image-collage-section p {
  padding: 140px 6.6vw 276px 46vw;
}
.mobile-immerse {
  display: none !important;
}
/*
|----------------------------------------------------------------
| Image collage
|----------------------------------------------------------------
*/
.image-collage {
  display: grid;
  grid-gap: calc(15 / 1920 * 100vw);
  grid-template-columns: repeat(3, minmax(0, 1fr));
  justify-content: center;
  margin: 0px 15vw;
}

.mobile-image-collage {
  display: none;
}

.image-collage ._img0 {
  padding-left: calc(115 / 1920 * 100vw);
  padding-bottom: calc(110 / 1920 * 100vw);
  padding-right: calc(75 / 1920 * 100vw);
}
.image-collage ._img1 {
  grid-row: span 2;
  padding: calc(50 / 1920 * 100vw) 0;
}
.image-collage ._img2 {
  padding-left: calc(75 / 1920 * 100vw);
}
.image-collage ._img3 {
  padding-right: calc(75 / 1920 * 100vw);
}
.image-collage ._img4 {
  padding: 0 calc(75 / 1920 * 100vw);
  padding-top: calc(109 / 1920 * 100vw);
}
.image-collage ._img5 {
  grid-column: 1/-1;
  padding: 0 calc(410 / 1920 * 100vw);
  /* padding-top: calc(20 / 1920 * 100vw); */
}

.image-collage ._img0 ._img_inner {
  height: calc(229 / 1920 * 100vw);
}
.image-collage ._img1 ._img_inner {
  height: calc(583 / 1920 * 100vw);
}
.image-collage ._img2 ._img_inner {
  height: calc(346 / 1920 * 100vw);
}
.image-collage ._img3 ._img_inner {
  height: calc(347 / 1920 * 100vw);
}
.image-collage ._img4 ._img_inner {
  height: calc(231 / 1920 * 100vw);
}
.image-collage ._img5 ._img_inner {
  height: calc(261 / 1920 * 100vw);
}

.image-collage ._img_inner {
  height: calc(292 / 1920 * 100vw);
  position: relative;
}
.image-collage img {
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

/*
|----------------------------------------------------------------
| Map
|----------------------------------------------------------------
*/
.map {
  background-color: #f0ebe6;
}
.map .details-wrapper {
  position: absolute;
  left: 4.5vw;
  top: 30px;
  z-index: 1;
}
.map .map-icon {
  width: 17.91px;
  height: 29.04px;
}
.mobile-map-details {
  display: none;
}
.map .details {
  margin-top: 70px;
}
.map .details li {
  margin-bottom: 30px;
}
.map .arrow-icon {
  width: 21px;
  height: 20px;
  margin-right: 7.8px;
}
.map .details li:nth-child(2) .arrow-icon {
  transform: rotate(-90deg);
}
.map .details li:nth-child(3) .arrow-icon {
  transform: rotate(-135deg);
}
.map .details li:nth-child(4) .arrow-icon {
  transform: rotate(180deg);
}
.map .details li:nth-child(5) .arrow-icon {
  transform: rotate(90deg);
}
.map .min {
  font-family: FS-Siena-Regular;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0px;
  margin-left: 8px;
  text-transform: uppercase;
}
.map .location {
  display: block;
  font-family: GT-Ultra-Fine-Regular;
  font-weight: normal;
  letter-spacing: -2px;
  font-size: 24px;
  line-height: 31px;
  text-transform: none;
}

/*
|----------------------------------------------------------------
| NOVARA
|----------------------------------------------------------------
*/
.novara {
  padding: 200px 11.25vw 265px;
  /* text-align: center; */
  background-color: #ffffff;
}
.novara .row {
  justify-content: space-between;
  align-items: center;
  margin: 0;
}
.novara .novara-logo {
  width: 270.21px;
  height: auto;
}
.novara .ashlyn-sideview-m {
  display: none;
}
.novara p {
  font-family: FS-Siena-Regular;
  font-weight: normal;
  line-height: 26px;
  font-size: 18px;
  letter-spacing: 0px;
  color: #2a4549;
  width: 107%;
  margin-top:80px;
}
.novara a {
  font-family: FS-Siena-Regular;
  font-weight: normal;
  text-transform: uppercase;
  font-size: 18px;
  text-decoration: underline;
  color: #2a4549;
}
/*
|----------------------------------------------------------------
| Register from
|----------------------------------------------------------------
*/
.register-form {
  padding: 185px 10.5vw 200px;
  background-color: #dce3e2;
}
.register-form .container {
  max-width: 1520px;
}
.register-form .intro {
  justify-content: space-between;
  align-items: baseline;
}
.register-form p {
  font-family: FS-Siena-Regular;
  font-weight: normal;
  line-height: 26px;
  font-size: 18px;
  letter-spacing: 0px;
  color: #3b545a;
  width: 80%;
  margin-left: auto;
}

/*  form */
.register-form .form {
  text-align: center;
}
.register-form .form .details {
  display: flex;
  flex-wrap: wrap;
  padding: 170px 0px 102px;
  text-align: left;
}
.form .wrapper {
  font-family: FS-Siena-Regular;
  font-weight: normal;
  line-height: 26px;
  letter-spacing: 2px;
  width: 470px;
  margin-bottom: 100px;
  margin-right: 30px;
}
.form label {
  font-size: 14px;
  color: #3b545a;
  text-transform: uppercase;
}
.form .wrapper > input {
  width: 100%;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid rgba(59, 84, 90, 0.5);
  margin-bottom: 15.5px;
  font-size: 20px;
  color: #2a4549;
  height: 40px;
  border-radius: 0px;
  padding-bottom: 1px;
}

.form input:focus {
  outline: none;
  background: transparent;
}
.form input:-webkit-autofill,
.form input:-webkit-autofill:hover,
.form input:-webkit-autofill:focus,
.form input:-webkit-autofill:active {
  transition: background-color 1000s;
  -webkit-text-fill-color: #2a4549 !important;
}

.checkbox-wrapper {
  display: flex;
  width: 542px;
}

.form .registerSelect {
  margin-bottom: 15.5px;
  font-size: 20px;
  color: #2a4549;
}
.form .registerSelect > div {
  border: none;
  border-bottom: 1px solid rgba(59, 84, 90, 0.5);
  /* border-radius: 0px; */
  font-size: 20px;
  color: #2a4549;
}
.registerSelect > div > div:nth-child(1) > div {
  color: #2a4549;
}

.form .checkbox-wrapper {
  padding-top: 40px;
}
.form .checkbox {
  width: 200px !important;
  height: 25px !important;
  color: #3b545a !important;
  background-color: transparent !important;
  border: 1px solid #3b545a !important;
}
.form .agreement {
  color: #3b545a;
  letter-spacing: 0.5px;
}
.form .is-small {
  font-family: FS-Siena-Regular;
  font-weight: normal;
  display: inline-block;
  font-size: 10px;
  line-height: 10px !important;
  opacity: 0.5;
  margin-top: 15px;
  color: #3b545a;
  letter-spacing: 0.5px;
}

.mobile-version.is-small {
  display: none;
}

.submit-btn {
  display: inline-block;
  background-color: #2a4549;
  padding: 12px 130px;
  color: #ffffff;
  border-radius: 25px;
  margin: 0 auto;
}
.submit-btn:hover {
  background-color: #2a4549;
  color: #ffffff;
}

.form .error {
  color: red;
}

/*
|----------------------------------------------------------------
| footer
----------------------------------------------------------------
*/
.footer {
  padding-top: 82px;
  padding-bottom: 70px;
  background-color: #2a4549;
}
.footer .container {
  overflow: hidden;
}
.footer .container .row {
  padding: 0px 6vw;
  justify-content: space-between;
  align-items: flex-end;
}
.footer h3 {
  text-transform: uppercase;
  font-size: 22px;
  line-height: 28px;
}
.footer .address {
  font-family: FS-Siena-Regular;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  color: #ffffff;
  margin: 24px 0px 30px;
}

.footer .social-medias img {
  width: 27.63px;
  height: 27.2px;
}
.footer .social-medias img:nth-child(2) {
  margin: 0px 10.6px;
}

.footer .right-col {
  padding: 0px;
  padding-left: 7vw;
}
.footer .novara-macdonald {
  display: flex;
}
.footer .novara-logo-icon {
  width: 131.6px;
  height: auto;
}
.footer .macdonald-logo-icon {
  width: 125px;
  height: auto;
  margin-left: 16px;
}
.footer .right-col .term {
  font-family: FS-Siena-Regular;
  font-weight: normal;
  font-size: 12px;
  line-height: 26px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #b2cec9;
  margin: 25px 0px 8px;
  width: 140%;
}
.footer .right-col .term a {
  color: #b2cec9;
}
.footer .right-col .copyright {
  font-family: FS-Siena-Regular;
  font-weight: normal;
  font-size: 10px;
  line-height: 10px;
  color: rgba(178, 206, 201, 0.5);
  width: 140%;
}
.footer .ashlyn-large-logo {
  width: 100%;
  height: auto;
  margin-top: 198px;
}

/*
|----------------------------------------------------------------
| Query media
|----------------------------------------------------------------
*/

@media (max-width: 1898px) {
  /* form */
  .register-form .intro {
    flex-direction: column;
  }
  .register-form p {
    margin-left: 0px;
    width: 100%;
    margin-top: 60px;
  }

  .register-form .form {
    text-align: left;
  }
  .register-form .form .details {
    justify-content: left;
  }
  .form .wrapper {
    margin-right: 60px;
  }
  .form .wrapper:nth-child(3n + 3) {
    margin-right: 60px;
  }
  /* footer */
  .footer .right-col {
    padding-left: 4vw;
  }
}

@media (max-width: 1700px) {
  /* font change */
  h1 {
    font-size: clamp(50px, 5vw, 88px);
    line-height: clamp(65px, 6vw, 115px);
  }
  h2 {
    font-size: clamp(30px, 3.3vw, 48px);
    line-height: clamp(39px, 4vw, 63px);
  }
  p {
    font-size: clamp(18px, 2vw, 28px);
    line-height: clamp(22px, 2.2vw, 36px);
  }

  /* nav */
  .nav .ashlyn-literal-logo {
    width: clamp(160px, 8.5vw, 180px);
  }
  .nav .ashlyn-logo {
    width: clamp(60px, 4vw, 76px);
  }
  .nav .register-btn-wrapper {
    padding: clamp(10px, 1vw, 12px) clamp(30px, 1.5vw, 42px);
  }
  /* landing */
  .landing-loader img {
    width: clamp(300px, 30vw, 647.4px);
  }
  /* bnr */
  .bnr {
    min-height: 650px;
  }
  .bnr .subject {
    line-height: clamp(22px, 2vw, 29px);
    font-size: clamp(20px, 1.5vw, 26px);
    margin-bottom: clamp(20px, 2vw, 29px);
  }
  .bnr .heading-wrapper {
    bottom: clamp(40px, 5.3vw, 82px);
  }
  .bnr h1 .is-lightgreen {
    margin-top: -15px;
  }
  /* unveil-homes */
  .unveil-homes h2 {
    margin-bottom: clamp(88px, 8.3vw, 170px);
  }
  .unveil-homes p {
    margin-bottom: clamp(150px, 16vw, 280px);
  }
  /* image-collage */
  .image-collage-section h2 {
    margin-top: clamp(100px, 9.5vw, 205px);
    margin-bottom: clamp(70px, 5vw, 156px);
  }
  .image-collage-section p {
    padding-top: clamp(100px, 5.5vw, 140px);
    padding-bottom: clamp(150px, 16vw, 276px);
  }
  /* map */
  .map .details {
    margin-top: clamp(40px, 1.5vw, 70px);
  }
  /* novara */
  .novara {
    padding: clamp(150px, 9.5vw, 200px) 11.25vw clamp(170px, 15vw, 265px);
  }
  .novara .novara-logo {
    width: clamp(200px, 15vw, 270.21px);
  }
  .novara p,
  .novara a,
  .register-form p {
    line-height: clamp(20px, 1.5vw, 26px);
    font-size: clamp(14px, 1vw, 18px);
  }
  .novara p {
    margin-top:clamp(27px, 2vw, 80px);
  }

  /* register form */
  .register-form {
    padding: clamp(103px, 9vw, 185px) 10.5vw clamp(150px, 9.5vw, 200px);
  }
  .register-form p {
    margin-top: clamp(34px, 1.2vw, 60px);
  }
  .register-form .form .details {
    padding: clamp(120px, 8.5vw, 170px) 0px clamp(60px, 5.5vw, 102px);
  }
  .form .wrapper {
    margin-bottom: clamp(30px, 3vw, 100px);
  }
  .form input {
    height: clamp(20px, 2vw, 30px);
    margin-bottom: clamp(10px, 1vw, 15.5px);
  }
  .form label {
    font-size: clamp(12px, 1vw, 14px);
  }

  .submit-btn {
    padding: clamp(10px, 1vw, 12px) clamp(95px, 7vw, 130px);
  }

  /* footer */
  .footer {
    padding-bottom: clamp(50px, 1.5vw, 70px);
  }
  .footer h3 {
    font-size: 22px;
    line-height: 28px;
  }
  .footer .ashlyn-large-logo {
    margin-top: clamp(120px, 9.5vw, 198px);
  }
}

@media (max-width: 1400px) {
  /* bnr */
  .bnr {
    min-height: 610px;
  }
  .bnr h1 .is-lightgreen {
    margin-top: -10px;
  }
  /* map */
.map .details li {
  margin-bottom: 25px;
}
  /* form agreement */
  .form .wrapper.checkbox-contianer {
    padding-right: 60px;
  }
  .desktop-version.is-small {
    display: none;
  }
  .mobile-version.is-small {
    display: block;
    width: 500px;
  }
  .form .checkbox {
    width: 25px !important;
    margin-right: 10px;
    margin-bottom: 0;
  }
  /* footer */
  .footer .right-col {
    padding-left: 0vw;
  }
}

@media (max-width: 1265px) {
  .form .wrapper {
    margin-bottom: 30px;
  }
  .checkbox-wrapper {
    margin-top: 30px;
  }
  .footer .right-col .copyright {
    width: 120%;
  }
}

@media (max-width: 1024px) {
  .desktop-map-details {
    display: none;
  }
  .mobile-map-details {
    display: flex;
    flex-wrap: wrap;
  }
  .map .mobile-map-details li {
    margin-right: 30px;
  }
  .mobile-map-details h3 {
    color: #3b545a;
  }
  .map .details {
    margin-left: 4.5vw;
  }
  .map .details li:nth-child(2) .arrow-icon {
    transform: rotate(90deg);
  }
  .map .details li:nth-child(3) {
    margin-right: 68px;
  }
  .map .details li:nth-child(3) .arrow-icon {
    transform: rotate(-90deg);
  }
  .map .details li:nth-child(5) .arrow-icon {
    transform: rotate(-135deg);
  }
}

@media (max-width: 992px) {
  /* unveil homes */
  .unveil-homes p {
    width: 85%;
  }
  /* noovara */
  
.novara .ashlyn-sideview-m {
  display: block;
} 
.novara .ashlyn-sideview {
  display: none;
}
.novara .novara-logo {
  margin-bottom: 55.6px;
}
.novara p {
  margin-top: 50px;
  width: 100%;
}
  /* form */
  .form .checkbox {
    margin-right: 10px;
  }
  .form .agreement {
    font-size: 12px;
    line-height: 26px;
  }
  /* footer */
  .footer .container .row {
    flex-direction: column;
    align-items: flex-start;
  }
  .footer .right-col {
    margin-top: 310px;
  }
  .footer .right-col .copyright {
    width: 140%;
  }
  .footer .ashlyn-large-logo {
    margin-top: 100px;
  }
}

@media (max-width: 768px) {
  .nav .ashlyn-logo {
    display: none;
  }
  /* unveil homes */
  .image-collage-section p {
    padding-right: 35vw;
  }
  /* form */
  .form .wrapper {
    margin-right: 0px;
  }
  .form .wrapper:nth-child(3n + 3) {
    margin-right: 0px;
  }
  /* footer */
  .footer .right-col .copyright {
    width: 90%;
  }
}

@media (max-width: 767px) {
  .novara p {
  }
}

@media (max-width: 667px) {
  .image-collage {
    grid-gap: 12px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding: calc(20 / 375 * 100vw);
    grid-template-rows: auto calc(32 / 375 * 100vw) auto calc(32 / 375 * 100vw) auto;
    margin: 0;
  }
  .image-collage ._img0 {
    padding: 0;
    padding-left: calc(30 / 375 * 100vw);
    padding-top: calc(28 / 375 * 100vw);
  }
  .image-collage ._img1,
  .image-collage ._img2,
  .image-collage ._img3 {
    padding: 0;
    grid-row: span 2;
  }
  .image-collage ._img4 {
    padding: 0;
    grid-row: span 2;
    padding-left: calc(30 / 375 * 100vw);
    padding-bottom: calc(108 / 375 * 100vw);
  }
  .image-collage ._img5 {
    padding: 0;
    grid-column: span 1;
    padding-right: calc(30 / 375 * 100vw);
  }

  .image-collage ._img0 ._img_inner {
    height: calc(158 / 375 * 100vw);
  }
  .image-collage ._img1 ._img_inner {
    height: calc(232 / 375 * 100vw);
  }
  .image-collage ._img2 ._img_inner {
    height: calc(188 / 375 * 100vw);
  }
  .image-collage ._img3 ._img_inner {
    height: calc(188 / 375 * 100vw);
  }
  .image-collage ._img4 ._img_inner {
    height: calc(92 / 375 * 100vw);
  }
  .image-collage ._img5 ._img_inner {
    height: calc(158 / 375 * 100vw);
  }

  /* map */

  .map .mapImg-wrapper {
    overflow-x: scroll;
  }
  .mapImg-wrapper img {
    height: 780px;
    width: auto;
  }
  .map-cover-wrapper {
    position: absolute;
    width: 1499px;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .map-cover {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #707070;
    mix-blend-mode: multiply;
    z-index: 0;
    pointer-events: none;
  }
  .map-cover-wrapper img {
    width: 178px;
    height: auto;
    pointer-events: none;
  }
  .map .details li:nth-child(3){
    margin-right: 93px;
  }
}

@media (max-width: 575px) {
  .global-padding {
    padding: 0 6vw;
  }
  /* font change */
  h1 {
    font-size: 50px;
    line-height: 65px;
    width: 90%;
  }
  h2 {
    font-size: 30px;
    line-height: 39px;
  }
  p {
    font-size: 18px;
    line-height: 22px;
  }

  /* nav */
  header {
    padding-top: 34px;
    padding-bottom: 37px;
  }
  .nav .ashlyn-literal-logo {
    width: 160px;
  }
  .nav .register-btn-wrapper {
    padding: 10px 30px;
  }
  /* landing */

  .landing-loader img {
    width: 300px;
  }
  /* bnr */
  .bnr {
    height: 590px;
  }
  .bnr .subject {
    line-height: 22px;
    font-size: 20px;
    margin-bottom: 10px;
    letter-spacing: 1px;
  }
  .bnr .heading-wrapper {
    bottom: 40px;
  }
  .bnr .is-lightgreen {
    /* font-size: 30px;
    margin-top: -20px; */
    margin-top: 0px;
  }
  .bnr .is-lightgreen span{
    top: -10px;
  }
  /* unveil-homes */
  .unveil-homes h2 {
    margin-top: 153px;
    margin-bottom: 94px;
  }
  .unveil-homes p {
    margin-bottom: 150px;
  }
  .img-al {
    padding: 0px;
  }
  /* image-collage */
  .image-collage-section h2 {
    font-size: 30px;
    line-height: 39px;
    margin-top: 100px;
    margin-bottom: 70px;
  }
  .image-collage-section p {
    padding-top: 100px;
    padding-bottom: 150px;
    padding-left: 0px;
  }
  .desktop-immerse {
    display: none !important;
  }

  .mobile-immerse {
    display: inline-block !important;
  }
  .image-collage {
    padding: 0px;
  }
  /* map */
  .map .details {
    margin-top: 40px;
  }
  /* novara */
  .novara {
    padding: 150px 6vw 170px;
    text-align: left;
  }
  .novara .novara-logo {
    width: 200px;
  }
  .novara p,
  .novara a,
  .register-form p {
    line-height: 20px;
    font-size: 14px;
  }
  .novara p {
    width: 82%;
  }

  /* register form */
  .register-form {
    padding: 103px 6vw 150px;
  }
  .register-form p {
    margin-top: 34px;
  }
  .register-form .form .details {
    padding: 120px 0px 60px;
  }
  .form .wrapper {
    margin-bottom: 15px;
  }
  .form input {
    height: 20px;
    margin-bottom: 10px;
  }
  .form label {
    font-size: 12px;
  }
  .form .checkbox-wrapper {
    padding-top: 30px;
  }
  .mobile-version.is-small {
    width: 100%;
  }

  .submit-btn {
    padding: 10px 95px;
  }

  /* footer */
  .footer {
    padding: 100px 6vw 50px;
  }
  .footer h3 {
    font-size: 22px;
    line-height: 28px;
  }
  .footer .ashlyn-large-logo {
    margin-top: 30px;
  }
  .footer .right-col .copyright {
    width: 95%;
  }
}

@media (max-width: 455px) {
  .mobile-map-details {
    flex-direction: column;
  }
  h1 {
    width: 100%;
  }
}

@media (max-width: 430px) {
  .mobile-version.is-small {
    max-width: 355px;
  }
  .bnr .subject {
    font-size: 18px;
  }
}
