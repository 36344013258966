
.bnr-privacy {
  padding-top: 311px!important;
  padding-bottom: 300px!important;
  padding-right: 27vw!important;
}
.bnr-privacy h2 {
  color: #B2CEC9;
}
.bnr-privacy p {
  font-family: FS-Siena-Regular;
  font-size: 22px;
  line-height: 30px;
  color: #FFFFFF;
  letter-spacing: normal;
  margin-bottom: 40px;
}
.bnr-privacy h3 {
  font-size: 30px;
  line-height: 39px;
  letter-spacing: -2px;
  color: #B2CEC9;
  text-transform: uppercase;
  margin: 53px 0 50px;
}
.bnr-privacy .intro {
  margin: 100px 0 103px;
}

@media (max-width: 1700px) {
  .bnr-privacy {
    padding-top: clamp(203px,17vw,311px)!important;
    padding-bottom: clamp(150px,16.5vw,300px)!important;
  }
  .bnr-privacy h3 {
    margin-bottom:clamp(30px,3.5vw,50px);
  }
  .bnr-privacy .intro {
    margin: clamp(30px,5.5vw,100px) 0 clamp(53px,5.5vw,103px);
  }
  .bnr-privacy p {
    font-size: clamp(14px,1.5vw,22px);
    line-height: clamp(20px,2vw,30px);
    margin-bottom: clamp(30px,3vw,40px);
  }

}

@media (max-width: 575px) {
  .bnr-privacy {
    padding-top: 203px!important;
    padding-bottom: 150px!important;
    padding-right: 6vw!important;
  }
  .bnr-privacy h3 {
    margin-bottom:30px;
  }
  .bnr-privacy .intro {
    margin: 30px 0 53px;
  }
  .bnr-privacy p {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 30px;
  }

}
